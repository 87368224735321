import * as $$ from "jquery-selector-cache";

/**
 * Opens chosen select on focus
 * @param chosenWrapperSelector selector for the wrapper element of the chosen select
 * @param chosenSelector selector for the chosen select
 */
export function initChosenOpenOnFocus(chosenWrapperSelector:string, chosenSelector: string) {
    const selectWrapper = $$(chosenWrapperSelector.trim());
    selectWrapper.on("focusin focus", ".chosen-container:not(.chosen-container-active) input", e => {
        $(e.delegateTarget).find(chosenSelector).trigger("chosen:open");
    });
}