import {UAParser} from "ua-parser-js";

let uaparser;
let device: IUAParser.IDevice;

/**
 * Initialize the User Agent
 */
export function initDevice() {
    uaparser = new UAParser();
    device = uaparser.getDevice();

    if (device.type === "mobile") {
        document.querySelector("body").classList.add("is-mobile");
    }
    if (device.type === "tablet") {
        document.querySelector("body").classList.add("is-tablet");
    }
}

/**
 * Check if a device type is mobile
 * @returns {boolean}
 */
export function isMobileDevice() {
    return device.type === "mobile";
}

/**
 * Check if a device type is tablet
 * @returns {boolean}
 */
export function isTabletDevice() {
    return device.type === "tablet";
}

/**
 * Get the actual device
 * @return {string}
 */
export function getDevice(): string {
    return device.type;
}