import "mdn-polyfills/String.prototype.includes";
import "mdn-polyfills/Array.prototype.find";

import {initClickable} from "orxapi.tools.clickable";
import {initDevice} from "./device";
import {initLazyLoading} from "./lazyload";
import {getBreakpointsCallbacks, getBreakpointsNames, initStartingBreakpoints} from "./breakpoints";

// Initialize the right breakpoint on current page.
initStartingBreakpoints();

// Initialize the User Agence devices
initDevice();

// Initialize Lazy load
initLazyLoading();

// Initialize default clickable
initClickable();

// Breakpoints custom event: initialize the breakpoints handler.
const breakpointsCallbacks = getBreakpointsCallbacks();
const breakpointsNames = getBreakpointsNames();
breakpointsNames.forEach((name) => Breakpoints.on(name, breakpointsCallbacks));