import * as $$ from "jquery-selector-cache";
import {openPopin} from "../popin/popin";
import {focusPopinNewsLetterForm, initPopinNewsLetterForm} from "../newsletterForm/newsletterForm";
import {deleteSessionUser} from "../../modules/facebook/facebook";

export function initHeader() {
    const accountMenu = $$(".account .account-menu");
    if (accountMenu.length) {
        $.ajax(accountMenu.data("ajaxUrl")).done((data) => {
            const retrievedMenu = $(data);
            accountMenu.find(".nav-link").prepend(retrievedMenu.find(".nav-link").html());
            accountMenu.find(".dropdown").append(retrievedMenu.find(".dropdown").html());
        });
    }

    if ($$(".logout-btn").length) {
        $$(".account").on("click", ".logout-btn", handleLogout);
    }

    if ($$("a[href='#pop-in-NL']").length) {
        $$("a[href='#pop-in-NL']").on("click", () => {
            openPopin($(".popin-newsletter"));
            focusPopinNewsLetterForm();
        });
        initPopinNewsLetterForm();
    }

    const homeMenuLink = $$("a.nav-link.home");
    handleHomeNavElem(homeMenuLink);
    homeMenuLink.on("mouseenter", handleHomeNavHover);
    homeMenuLink.on("mouseleave", handleHomeNavEvt);
}

$("#1_navitem").on("click", () => {
    const chbx = $("#chx_newsletter-receive-offer");
    $("#ipt_newsletter-email").val("");
    if (chbx.prop("checked") == true) {
        chbx.trigger("click");
    }
    $("#popinNewsletterForm").find(".elt_message.success").addClass("hidden");
});

export function initMmenu() {
    $$(".real-link").on("click", evt => window.location.href = $(evt.currentTarget).attr("href"));

    $$(".menu-entry-list").on("mouseenter", "li", handleLinkMouseEnter);
    $$(".menu-entry-list").on("mouseleave", "li", handleLinkMouseLeave);

    $$(".header-menu .mr-auto .nav-item").on("click", (evt) => {
        evt.stopPropagation();
        const elem = $(evt.currentTarget);
        const link = $$(evt.currentTarget).children(".nav-panel").data("link");

        // add class on item when panel is open
        $$(".header-menu .mr-auto .nav-item").children(".nav-panel").removeClass("panel-open");
        $$(evt.currentTarget).children(".nav-panel").addClass("panel-open");

        // hide panel
        $$(".header-smenu > .container > .row").addClass("hidden");

        if (elem.children().hasClass("nav-panel")) {
            $$(".header-smenu ." + link).removeClass("hidden");
            $$(".header-smenu").addClass("open");

            $$(".header-smenu").on("mouseleave", () => {
                $$(".header-smenu").removeClass("open");
                $$(".header-menu .mr-auto .nav-item").children(".nav-panel").removeClass("panel-open");
            });

            $$(".navbar").on("mouseleave", () => {
                $$(".header-smenu").removeClass("open");
                $$(".header-menu .mr-auto .nav-item").children(".nav-panel").removeClass("panel-open");
            });

        } else {
            $$(".header-smenu").removeClass("open");
        }
    });

    $$(".header-menu .navbar-nav.account>.nav-item>.nav-link").on("click", (e) => {
        $$(".inscriptionPopin").addClass("open");
    });

    $$(".items-menu-responsive.account").on("click", (e) => {
        // todo check overflow block scroll bar after click on account picto
        // $$("body").css("overflow", "hidden");
        $$(".inscriptionPopin").addClass("open");
        $$(".inscriptionPopin").css("overflow", "scroll");
        $$(".inscriptionPopin .backdrop").css("height", "210vh");
    });

    $$(".burger-icon.items-menu-responsive").on("click", (e) => {
        if ($$(".header-menu-responsive").hasClass("open")) {
            $$(".header-menu-responsive").removeClass("open");
        } else {
            $$(".header-menu-responsive").addClass("open");
        }
    });

    /*  tooggle Mobile sub level */
    $$(".header-menu-responsive .nav-item > a").on("click", (e) => {
       const list = $$(e.currentTarget).parent().find("> .header-menu-responsive-list");
       if (list) {
           e.preventDefault();
       }
       if (list.hasClass("open")) {
           $$(e.currentTarget).removeClass("open-sub");
           list.removeClass("open");
       } else if (list) {
           $$(e.currentTarget).addClass("open-sub");
           list.addClass("open");
       }
    });


    $$(".inscriptionPopin .backdrop").on("click", (e) => {
        $$(".inscriptionPopin").removeClass("open");
        $$("body").css("overflow", "auto");
    });

    $$(".header-menu .navbar-nav.account .dropdown .deconnexion").on("click", (e) => {
        $$("body").addClass("active-popinDeconnect-deconnect");
    });
}

function handleLinkMouseEnter(evt: JQuery.Event<HTMLLIElement>) {
    const element = $(evt.currentTarget);
    const pictureUrl = element.data("hoverPicture");
    if (pictureUrl) {
        const figure = element.parents(".row").find(".map img");
        figure.attr("src", pictureUrl);
        figure.attr("alt", element.data("hoverPictureAlt"));
    }

}

function handleLinkMouseLeave(evt: JQuery.Event<HTMLLIElement>) {
    const element = $(evt.currentTarget);
    const figure = element.parents(".row").find(".map img");
    figure.attr("src", figure.data("basePicture"));
    figure.attr("alt", "");

}

function handleHomeNavHover(evt: JQuery.Event<HTMLLIElement>) {
    const element = $(evt.currentTarget);
    const figure = element.find("img");
    figure.attr("src", figure.data("hover"));
}

function handleHomeNavEvt(evt: JQuery.Event<HTMLLIElement>) {
    handleHomeNavElem($(evt.currentTarget));
}

function handleHomeNavElem(element: JQuery<HTMLLIElement>) {
    const figure = element.find("img");
    handleHomeNav(element, figure);
}

function handleHomeNav(element: JQuery<HTMLLIElement>, figure: JQuery<HTMLLIElement>) {
    if (element.hasClass("active")) {
        figure.attr("src", figure.data("active"));
    } else {
        figure.attr("src", figure.data("base"));
    }
}

export function handleLogout(evt) {
    evt.preventDefault();
    const logoutButton = $(evt.currentTarget);
    deleteSessionUser();
    $.ajax(logoutButton.data("logout")).done(() => window.location.href = logoutButton.data("callback"));
}

// popin log out
export function handleOpenLogoutPopin(evt: JQuery.Event) {
    const target = $$(evt.currentTarget);
    openPopin($(target.data("popin")));
}

$$("header .account").on("click", ".btn-logout", handleOpenLogoutPopin);