import * as Blazy from "blazy";

/** Fix a Type Definition Error */
interface BlazyInstanceFix extends BlazyInstance {
    load(elements: Element|Element[]|HTMLElement|HTMLElement[]|NodeList|JQuery, force: boolean): void;
}

// Local variable
let bLazy: BlazyInstanceFix;

/**
 * Initialize the lazy load
 */
export function initLazyLoading() {
    bLazy = new Blazy({
        offset : 150
    });
}

/**
 * Load the image with lazy
 * @param elem
 */
export function loadImageHidden(elem: JQuery) {
    bLazy.load(elem, true);
}

/**
 * Ojbect-fit condition for loading
 * @param img
 * @returns {boolean}
 */
export function handleConditionSuccess(img: HTMLImageElement) {
    const image = $(img);
    return image.hasClass("b-lazy") ? image.hasClass("b-loaded") : true;
}