import * as $$ from "jquery-selector-cache";
import * as querystring from "query-string";

import "../../_core/scripts/specific/layout/index";
import {initMmenu} from "../../components/header/header";
import {closeNotification} from "../../components/notification/notification";
import {initBackToTop} from "../../components/backtop/backtop";
import {initFooter} from "../../components/footer/footer";
import {openPopin, closePopin} from "../../components/popin/popin";
import {initUserRegistrationForm} from "../../components/userRegistrationForm/userRegistrationForm";

initMmenu();
initFooter();
initBackToTop();
closePopin();
initUserRegistrationPopin();
initUserRegistrationForm();

$$(".notification").on("click", ".close", closeNotification);
$$(".password-wrap").on("click", ".btn-unmask-password", initUnmaskPassword);
$$("#loginWrapper").on("click", ".forgotten-password", handleClickResetUser);
$$("#loginWrapper").on("click", ".back-login-btn", handleClickLoginUser);
$$("#connectionForm").on("submit", handleConnection);
$$("#userResetForm").on("submit", handleSendResetConnect);
$$("#userPasswordForm").on("submit", handleNewPassword);

function handleClickResetUser(evt: JQuery.Event) {
    const target = $$(evt.currentTarget);
    target.parents(".connection").addClass("hidden");
    target.parents(".connection").next(".reset").removeClass("hidden");
}

function handleClickLoginUser(evt: JQuery.Event) {
    const target = $$(evt.currentTarget);
    target.parents(".reset").addClass("hidden");
    target.parents(".reset").prev(".connection").removeClass("hidden");
}

function handleConnection(evt: JQuery.Event) {
    evt.preventDefault();
    const form = $(evt.currentTarget) as JQuery<HTMLElement>;
    $.post(
        form.attr("action"),
        form.serialize()
    ).done(data => {
        const callBackUrl = form.data("callbackurl") as string;
        if (callBackUrl) {
            return window.location.href = callBackUrl;
        } else {
            return window.location.href = data.callBackUrl;
        }
    }).fail(() => {
        loginErrorMessage();
    });
}

function handleSendResetConnect(evt: JQuery.Event) {
    evt.preventDefault();
    const form = $(evt.currentTarget) as JQuery<HTMLElement>;
    const data = JSON.stringify({
        email: form.find(".form-control.email").val(),
    });
    $.ajax({
        method: "POST",
        url: form.attr("action"),
        contentType: "application/json",
        type: "json",
        data
    }).done((response) => updateResetMessage(response === "true", form)
    ).fail(() => updateResetMessage(false, form));

}

function handleNewPassword(evt: JQuery.Event<HTMLFontElement>) {
    evt.preventDefault();
    const form = $(evt.currentTarget);
    const data = JSON.stringify({
        password: form.find("#newPassword").val(),
        token: form.find("#resetToken").val(),
        email: form.find("#resetEmail").val(),
    });
    $.ajax({
        method: "POST",
        url: form.attr("action"),
        contentType: "application/json",
        type: "json",
        data
    }).done((response) => {
        const responseOk = response === true;
        updateResetMessage(responseOk, form);
        if (responseOk) {
            window.location.href = form.data("callback");
        }
    }).fail(() => updateResetMessage(false, form));
}

function initUnmaskPassword(evt: JQuery.Event) {
    const target = $$(evt.currentTarget);
    const btnPassword = target.parent(".password-wrap").find(".password");

    if (target.hasClass("show")) {
        btnPassword.removeAttr("type");
        btnPassword.attr("type", "password");
        target.removeClass("show");
    } else {
        btnPassword.removeAttr("type");
        btnPassword.attr("type", "text");
        target.addClass("show");
    }
}

function initUserRegistrationPopin() {
    // Popin event
    $$(".link-wrap").on("click", ".create-account", () => openPopin($(".popin-account")));
}

function loginErrorMessage() {
    $$("#loginWrapper").find(".connection .message-wrap .login-error").removeClass("hidden");
}

function updateResetMessage(success: boolean, form: JQuery) {
    form.find(".reset-error").toggleClass("hidden", success);
    form.find(".reset-success").toggleClass("hidden", !success);
}