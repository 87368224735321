import * as $$ from "jquery-selector-cache";

const backtop =  $$(".backtop");

export function initBackToTop() {
  $$(window).scroll(() => {
    const top = $$(window).scrollTop();
    if (top > 200) {
      backtop.show();
    } else {
      backtop.hide();
    }
  });
  backtop.click(() => {
  $$("html, body").stop().animate({
      scrollTop: 0
    }, 300);
  return false;
  });
}