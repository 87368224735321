import * as $$ from "jquery-selector-cache";

export function handleSubmitPersonalDataForm(evt: JQuery.Event, callBack?: (response, form?) => void, fail?: (data, form) => void) {
    evt.preventDefault();

    const form = $(evt.currentTarget);
    const year = form.find(".year-birthdate").val();
    const month = form.find(".month-birthdate").val();
    const day = form.find(".day-birthdate").val();

    if (year && month && day) {
        form.find(".ipt-birthdate")
            .val(year + "-" + month + "-" + day);
    }

    if (form.find("#formValidation").val()) {
        fail(null, form);
        return;
    }

    const callAjax = $.ajax({
        method: "POST",
        url: form.attr("action"),
        contentType: "application/json",
        type: "json",
        data: JSON.stringify(formToJson(form))

    }).done(response => callBack(response, form));

    if (fail) {
        callAjax.fail(data => fail(data, form));
    }
}

export function focusPersonalDataForm() {
    const personalDataFormFirstInput = $$("#personalDataForm #ipt_personal-datas-last-name");
    if (personalDataFormFirstInput.length) {
        personalDataFormFirstInput.focus();
    }
}

export function fillPersonalDataFormWithMailandDisplayMessage() {
    const mail = localStorage.getItem("userMail");
    const personalDataForm = $(".form-personal-space");
    personalDataForm.find(".error-message").removeClass("hidden");
    const personalDataFormMailInput = $$("#personalDataForm #ipt_personal-datas-email");
    personalDataFormMailInput.val(mail);
}

function formToJson(form) {
    return form.serializeArray().reduce((m, o) => {
        if (m[o.name]) {
            m[o.name] += "," + o.value;
        } else {
            m[o.name] = o.value;
        }
        return m;
    }, {});
}