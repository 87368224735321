import {urlParametersConfig} from "../config/form";

/**
 * Push an event to the GTM Data Layer
 * @param evt triggered event
 * @param home flag to tell if the event is triggered from home page
 * @param action DataLayerPushAction type (SearchEngine, Newsletter, Inspiration, Book)
 */
export function dataLayerPush(evt: JQuery.Event, home: boolean, action: DataLayerPushAction) {
    let dataLayer = window["dataLayer"] = window["dataLayer"] || [];
    let dataLayerData = {};
    dataLayerData["event"] = "interaction";

    if (action == DataLayerPushAction.SearchEngine) {
        dataLayerData["categoryGA"] = "Landing";
        dataLayerData["actionGA"] = "Recherche";
        dataLayerData["labelGA"] = getSearchEngineCriteria(evt);
    } else if (action == DataLayerPushAction.Inspiration) {
        dataLayerData["categoryGA"] = "Recherche";
        dataLayerData["actionGA"] = "Inspiration";
        dataLayerData["labelGA"] = $(evt.currentTarget).val();
    } else if (action == DataLayerPushAction.Newsletter) {
        dataLayerData["categoryGA"] = "Conversion";
        dataLayerData["actionGA"] = "Inscription Newsletter";
        dataLayerData["labelGA"] = "Succès";
    }
    if (home && action != DataLayerPushAction.Newsletter) {
        dataLayerData["categoryGA"] = "Homepage";
    }

    if (action == DataLayerPushAction.Book) {
        const bookingForm = $(evt.currentTarget).parents("#bookingWrap");
        let reductionRate = getBookingCalendarCellData(evt, "promo");
        reductionRate = reductionRate == "" ? null : reductionRate;
        dataLayerData = {
            "event": "addToCart",
            "ecommerce": {
                "add": {
                    "products": [{
                        "name": bookingForm.data("title"),
                        "id": String(bookingForm.data("code")),
                        "price": parseFloat(getBookingCalendarCellData(evt, "price")),
                        "brand": bookingForm.data("formula"),
                        "category": bookingForm.data("destination"),
                        "coupon": reductionRate,
                        "dateDepart": getProductDepartureDate(bookingForm),
                        "villeDepart": getSelectChosenValue("departureBooking", bookingForm),
                        "participants": getBookingFormInputValue(bookingForm, "nbAdults") + " adultes, "
                            + getBookingFormInputValue(bookingForm, "nbChildren") + " enfants, "
                            + getBookingFormInputValue(bookingForm, "nbBabies") + " bébés",
                        "length": getSelectChosenValue("durationBooking", bookingForm),
                        "quantity": 1
                    }]
                }
            }
        };
    }

    if (window["debugModeEnabled"]) {
        console.log("GTM Data Layer Event :", JSON.stringify(dataLayerData, null, 4));
    }
    dataLayer.push(dataLayerData);
}

/**
 * Retrieves the value of the data from a cell of the booking calendar
 * @param evt the triggered event
 * @param cellClass class of the cell to retrieve the data
 */
function getBookingCalendarCellData(evt: JQuery.Event, cellClass: string) {
    const calendarCell = getClickedBookCell(evt);
    if (calendarCell.length) {
        const calendarCellElem = calendarCell.find("span." + cellClass);
        if (calendarCellElem) {
            return calendarCellElem.data("value") ? calendarCellElem.data("value") : "";
        }
    }
    return "";
}

/**
 * Retrieves the cell clicked in the booking calendar (desktop & mobile)
 * @param evt the triggered event
 */
function getClickedBookCell(evt: JQuery.Event) {
    const clickedElem = $(evt.currentTarget);
    if(clickedElem.hasClass("inner-cell") || clickedElem.hasClass("line")) {
        return clickedElem;
    }
    const innerCell = clickedElem.parents(".inner-cell");
    if(innerCell.length) {
        return innerCell;
    }
    const lineCell = clickedElem.parents(".line");
    if(lineCell.length) {
        return lineCell;
    }
    return null;
}

/**
 * Retrieves the value of an input of the generated booking form after book click
 * @param bookingForm the booking "form" (a div)
 * @param formInput the input name
 */
function getBookingFormInputValue(bookingForm: JQuery<EventTarget>, formInput: string) {
    const input = bookingForm.find("input[name=" + formInput + "]");
    if (input.length && input.val()) {
        return input.val();
    }
    return "";
}

/**
 * Formats the departure date from the availability string (example: formats 31-12-2019-3-2 to 31/12/2019)
 * @param bookingForm
 */
function getProductDepartureDate(bookingForm: JQuery<EventTarget>) {
    // Format : dd-MM-yyyy-days-nights (example: 31-12-2019-3-2)
    const availability = getBookingFormInputValue(bookingForm, "disponibility");
    if (availability) {
        const availabilityArray = String(availability).split("-");
        return availabilityArray[0] + "/" + availabilityArray[1] + "/" +availabilityArray[2];
    }
    return "";
}

/**
 * Formats the search engine criteria in the format destination|formula|departureCity|departureDate|flexibility| (example : Autriche|Circuit|Bordeaux|27/10/18|+/- 4 jours)
 * @param evt the triggered event
 */
function getSearchEngineCriteria(evt: JQuery.Event) {
    const searchEngineForm = $(evt.currentTarget).parents("#searchEngineForm");
    const destination = getSelectChosenValue(urlParametersConfig.destination, searchEngineForm);
    const formula = getSelectChosenValue(urlParametersConfig.formula, searchEngineForm);
    const departureCity = getSelectChosenValue(urlParametersConfig.departureCity, searchEngineForm);
    const departureDay = String($("input[type=hidden][name=" + urlParametersConfig.day + "]").val());
    const departureMonthYear = String($("input[type=hidden][name=" + urlParametersConfig.monthYear + "]").val());
    const departureDate = departureDay && departureDay.length > 0 && departureMonthYear && departureMonthYear.length > 0 ? (departureDay + "/" + departureMonthYear) : "";
    const flexibility = getSelectChosenValue(urlParametersConfig.flexibility, searchEngineForm);
    return destination + "|" + formula + "|" + departureCity + "|" + departureDate + "|" + flexibility;
}

/**
 * Retrieves the selected option of a select element (used with chosen plugin or not)
 * @param selectName the name of the select input
 * @param container parent container of the select input
 */
function getSelectChosenValue(selectName: string, container: JQuery<EventTarget>) {
    const select = container.find("select[name='" + selectName + "']");
    const selectedOption = select.find("option:selected");
    if (selectedOption.val()) {
        return selectedOption.text();
    }
    const chosenSelectedOption = selectedOption.siblings(".chosen-container").children(".chosen-single");
    const chosenSelectedOptionLabel = chosenSelectedOption.hasClass("chosen-default") ? "" : chosenSelectedOption.children("span").html();
    if (chosenSelectedOptionLabel) {
        return chosenSelectedOptionLabel;
    }
    return "";
}

/**
 * Data Layer actions
 */
export enum DataLayerPushAction {
    SearchEngine,
    Newsletter,
    Inspiration,
    Book
}