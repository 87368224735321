const FB_API = "413060112664251";
const FB_VERSION = "v4.0";
const FB_AUTH_SCOPES = "email,user_likes,public_profile";
const FB_API_SCOPES = "email,birthday,first_name,last_name,gender,location";
const FB_SESSION_KEY = "orx_fb_status";

const MESSAGES = {
  OK: "OK",
  MUST_BE_CONNECTED: "Vous devez être connecté à Facebook"
};

export const FB_STATUS = {
  CONNECTED: "connected",
  UNKNOWN: "unknown"
};

export function initFacebookSdk(callback) {
  if ($("#facebook-jssdk").length <= 0) {
    console.log("initFacebookSdk OK");

    window.fbAsyncInit = () => {
      FB.init({
        appId      : FB_API,
        cookie     : true,
        xfbml      : true,
        version    : FB_VERSION
      });

      FB.AppEvents.logPageView();

      if (callback) { callback("init"); }
    };

    (function(d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/fr_FR/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));
  }
}

export function fbLogin(callback?) {
  FB.login((res) => {
    fbSetSessionUser(res);
    if (callback) { callback(res); }
  }, {scope: FB_AUTH_SCOPES});
}

export function fbLogout(callback) {
  FB.logout((res) => {
    fbSetSessionUser(res);
    if (callback) { callback(res); }
  });
}

export function fbGetSessionUser() {
  const data = JSON.parse(localStorage.getItem(FB_SESSION_KEY)) || {};
  return data && data.auth ? data.auth : null;
}

export function fbSetSessionUser(auth) {

  const data = { auth, expireAt: new Date()};
  if (auth && auth.authResponse &&  auth.authResponse.expiresIn) {
    const dt = new Date();
    dt.setSeconds(dt.getSeconds() + auth.authResponse.expiresIn);
    data.expireAt = dt;
  }
  localStorage.setItem(FB_SESSION_KEY, data.auth ? JSON.stringify(data) : null);
}

export function deleteSessionUser() {
  localStorage.removeItem("orx_fb_status");
  localStorage.removeItem("userMail");
}

export function fbLoginStatus(callback) {
  FB.getLoginStatus(callback);
}

export function fbApi(url, callback) {
  FB.api(url, {"fields": FB_API_SCOPES}, callback);
}

export function fbOnlyGetUser(callback) {
  fbLoginStatus((s) => {
    if (s.status === FB_STATUS.CONNECTED) {
      fbApi(`/${s.authResponse.userID}`, (user) => {
        callback({message: MESSAGES.OK, user});
      });
    } else {
      callback({message: MESSAGES.MUST_BE_CONNECTED});
    }
  });
}