import * as $$ from "jquery-selector-cache";
import {openPopin} from "../popin/popin";
import {dataLayerPush, DataLayerPushAction} from "../../_core/scripts/specific/gtm";

export function initFooter() {
    // Submit newsletter subscription
    const footer = $(".specific-footer");
    footer.on("submit.handleNewsletterSubscription", ".newsletter-form", handleNewsletterSubscription);
    footer.on("submit.validateSubscribe", ".newsletter-form-valid", validateSubscribe);
    const form = footer.find(".newsletter-form");
    const nlValidator = $("#nlFormValid");
    if (form.find("input[name=email]").val()) {
        form.trigger("submit.handleNewsletterSubscription");
    } else if ($("#subvalid").val()) {
        nlValidator.trigger("submit.validateSubscribe");
    }
}

$("#popinCloser").on("click", () => {
    const form =  $(".specific-footer").find(".newsletter-form");
    form.find("input[name=email]").val("");
});

/**
 * Handle newsletter subscription
 * @param {JQueryEventObject} evt
 */
export function handleNewsletterSubscription(evt: JQuery.Event) {

    evt.preventDefault();

    const form = $(evt.currentTarget);
    const popinMessage = $$(".popin-newsletter-footer").find(".popin-main .message");
    const formData = {
        "email": form.find("input[name=email]").val(),
        "withNewsletter": true
    };
    // clear div message if not empty
    if (!popinMessage.is(":empty")) {
        popinMessage.empty();
    }
    $.ajax({
        method: "POST",
        url: "/newsletter/subscribe",
        data: JSON.stringify(formData),
        contentType: "application/json",
        type: "json"
    }).done(() => {
        popinMessage.append("Votre demande a bien été prise en compte, vous recevrez un email afin de valider votre inscription à la newsletter");
        openPopin($$(".popin-newsletter-footer"));
    }).fail(() => {
        popinMessage.append("Erreur lors de l'inscription à la newsletter, veuillez nous excuser");
        openPopin($$(".popin-newsletter-footer"));
    });

}

export function validateSubscribe(evt: JQuery.Event) {

    evt.preventDefault();

    const form = $(evt.currentTarget);
    const popinMessage = $$(".popin-newsletter-footer").find(".popin-main .message");
    const formData = {
        "email": form.find("input[name=email]").val(),
        "withNewsletter": true
    };
    // clear div message if not empty
    if (!popinMessage.is(":empty")) {
        popinMessage.empty();
    }
    $.ajax({
        method: "POST",
        url: "/newsletter/subvalid",
        data: JSON.stringify(formData),
        contentType: "application/json",
        type: "json"
    }).done(() => {
        popinMessage.append("Votre inscription à la newsletter de Visit Europe est confirmée.");
        dataLayerPush(evt, false, DataLayerPushAction.Newsletter);
        openPopin($$(".popin-newsletter-footer"));
    }).fail(() => {
        popinMessage.append("Erreur lors de l'inscription à la newsletter, veuillez nous excuser");
        openPopin($$(".popin-newsletter-footer"));
    });


}
