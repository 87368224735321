import * as $$ from "jquery-selector-cache";

/**
 * Initialize the close button popin
 */
export function initPopin() {
    // Exit when the component don't exist
    if (!$$(".cpt-popin").length) {
        return;
    }

    // Exit when the close button don't exist
    if (!$$(".cpt-popin").find(".popin-close").length) {
        return;
    }
}

/**
 * Open the popin
 * @param target
 */
export function openPopin(target: JQuery = $$(".cpt-popin")) {
    target.removeClass("hidden");
    $$("body").addClass("popin-is-open");
    $$(".cpt-popin").on("click.closePopin", ".popin-close", handleClosePopin);
}

/**
 * Handler click, close popin
 * @param {JQuery.Event} evt
 */
export function handleClosePopin(evt: JQuery.Event) {
    closePopin($(evt.delegateTarget));
}

export function closePopin(target: JQuery = $$(".cpt-popin")) {
    target.addClass("hidden");
    $$("body").removeClass("popin-is-open");
}