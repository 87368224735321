import * as $$ from "jquery-selector-cache";
import {closePopin} from "../popin/popin";

export function initUserRegistrationForm() {
    $$("#registerForm").on("submit", e => handleSubmitUserRegistrationForm(e));
}

function handleSubmitUserRegistrationForm(evt: JQuery.Event) {
    evt.preventDefault();
    const form = $(evt.currentTarget);

    const name = form.find("#ipt_account-name").val();
    const address = form.find("#ipt_account-adress").val();
    const postalCode = form.find("#ipt_account-postal-code").val();
    const city = form.find("#ipt_account-city").val();
    const country = form.find("#ipt_account-country").val();
    const phone = form.find("#ipt_account-phone").val();
    const mail = form.find("#ipt_account-mail").val();

    const validForm = name && address && postalCode && city && country && phone && mail;
    if(!validForm) {
        form.find(".success-form").remove();
        form.find(".error-form").remove();
        form.append("<div class='error-form elt_message error'>Merci de bien vouloir remplir tous les champs</div>");
    } else {
        evt.preventDefault();
        const form = $(evt.currentTarget);
        const data = JSON.stringify(formToJson(form));
        if (form.find("#formSecurity").val()){
            form.append("<div class='error-form elt_message error'>Une erreur est survenue lors de l'envoi de votre demande</div>");
            return;
        }
        $.ajax({
            url: form.attr("action"),
            method: "POST",
            contentType: "application/json",
            type: "json",
            data
        }).done((data) => {
            form.find(".success-form").remove();
            form.find(".error-form").remove();
            if (data == "true") {
                form.find(".account-submit").css("display", "none");
                form.find(".form-control").prop("disabled", true);
                form.append("<div class='error-form elt_message success'>Demande envoyée</div>");
            } else {
                form.append("<div class='error-form elt_message error'>Une erreur est survenue lors de l'envoi de votre demande</div>");
            }
        }).fail(() => {
            form.append("<div class='error-form elt_message error'>Une erreur est survenue lors de l'envoi de votre demande</div>");
        });
    }
}

function formToJson(form) {
    return form.serializeArray().reduce((m, o) => {
        if (m[o.name]) {
            m[o.name] += "," + o.value;
        } else {
            m[o.name] = o.value;
        }
        return m;
    }, {});
}