import * as $$ from "jquery-selector-cache";

import {handleSubmitPersonalDataForm} from "../personalDataForm/personalDataForm";
import {openPopin} from "../popin/popin";
import {initChosenOpenOnFocus} from "../../_core/scripts/specific/accessibility";

export function initPopinNewsLetterForm() {
    initForm("popinNewsletterForm");
}

export function initNewsLetterForm() {
    initForm("newsletterForm");
}

export function focusPopinNewsLetterForm() {
    focusForm("popinNewsletterForm");
}

export function focusNewsletterForm() {
    focusForm("newsletterForm");
}

function focusForm(formId) {
    const currentFormEmailInputName = "#" + formId + " .newsletter-email";
    const currentFormPhoneIndicativeInputName = "#" + formId + " .ipt_indicative";
    if ($$(currentFormEmailInputName).length) {
        $$(currentFormEmailInputName).focus();
    } else if ($$(currentFormPhoneIndicativeInputName).length) {
        $$(currentFormPhoneIndicativeInputName).focus();
    }
}

function initForm(formId) {

    // Open chosen on focus
    initChosenOpenOnFocus("#" + formId + " .elt_select", ".cpt-select-custom.cpt-with-search");

    $$("#" + formId).on("submit", e => handleSubmitPersonalDataForm(e,
        (response, form) => {
            form.find(".success-form").remove();
            form.find(".error-form").remove();
            if (response === true) {
                if (form.find("input[name='withNewsletter']").attr("checked")) {
                    openPopin($(".newsletter-popin"));
                } else {
                    form.append("<div class='error-form elt_message success'>Votre demande a bien été prise en compte, vous recevrez un email afin de valider votre inscription à la newsletter</div>");
                }
            } else {
                form.append("<div class='error-form elt_message error'>Une erreur est survenue pendant l'envoi du formulaire</div>");
            }
        },
        (data, form) => {
            form.find(".error-form").remove();
            return form.append("<div class='error-form elt_message error'>Une erreur est survenue pendant l'envoi du formulaire</div>");
        })
    );

    $$("#" + formId).on("click", ".item.checkboxLabel > label", e => {
        const checkbox = $(e.currentTarget).siblings("input:checkbox");
        if (checkbox) {
            if (checkbox.attr("checked")) {
                checkbox.removeAttr("checked");
            } else {
                checkbox.attr("checked", "checked");
            }
        }
    });
}